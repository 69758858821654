export const backendUrl = process.env.REACT_APP_APP_URL + '/.netlify/functions';
export const backendStaticUrl = 'https://nolarhair.com.ng';

export const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const links = [
  {
    name: 'home',
    to: '/',
  },
  {
    name: 'about',
    to: '/about',
  },
  {
    name: 'services',
    to: '/services',
  },
  {
    name: 'store',
    to: '/store',
  },
  {
    name: 'check booking',
    to: '/booking',
  },
];
