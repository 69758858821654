import React, { FC, useState } from 'react';
import { Row, Col, Button, Space } from 'antd';
import Text from 'antd/lib/typography/Text';
import { InputBar } from '../../Form/InputBar';
import { AiOutlineMail, AiOutlinePhone, AiOutlineUser } from 'react-icons/ai';
import { emailRegex } from '../../../utils/regex';
import { backendUrl, DEFAULT_HEADERS } from '../../../utils/constants';
import { CartProduct } from '../store.interface';
import { Loading } from '../../Loading/Loading';
import { useLocation } from 'react-router-dom';

export const Payment: FC<{
  // setReference: Dispatch<SetStateAction<string>>,
  selectedProducts: CartProduct[];
}> = (props) => {
  const [name, setName] = useState('');

  const [email, setEmail] = useState('');

  const [phone, setPhone] = useState('');

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState();

  const { search } = useLocation();

  const query = new URLSearchParams(search);

  const deliveryType = query.get('deliveryType');

  const getLink = async (
    email: string,
    name: string,
    selectedProducts: CartProduct[]
  ) => {
    setLoading(true);
    const body = {
      email,
      name,
      phoneNumber: phone,
      deliveryType,
      selectedProducts,
    };

    const response = await fetch(backendUrl + '/create-order', {
      headers: DEFAULT_HEADERS,
      method: 'POST',
      body: JSON.stringify(body),
    });

    const { data } = await response.json();

    const { checkoutLink: paymentUrl } = data;

    setData(data);

    setLoading(false);

    window.open(paymentUrl, '_self');
  };

  return (
    <Row gutter={[{}, 24]}>
      <Col span={24}>
        <Text>Payment Method</Text>
      </Col>
      <Col span={24}>
        <Space>
          <Button
            style={{
              border: '2px solid #4BB543',
              fontWeight: 600,
            }}
          >
            Paystack
          </Button>
          <Button disabled>Coming Soon</Button>
        </Space>
      </Col>
      <Col span={24}>
        <InputBar
          prefix={<AiOutlineUser></AiOutlineUser>}
          value={name}
          label={{ name: 'Full Name', key: 'name' }}
          color="#20274D"
          type="text"
          onChange={(e) => {
            setName(e.target.value);
          }}
        ></InputBar>
        <InputBar
          prefix={<AiOutlineMail></AiOutlineMail>}
          value={email}
          label={{ name: 'Email', key: 'email' }}
          color="#20274D"
          type="email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        ></InputBar>
        <InputBar
          prefix={<AiOutlinePhone />}
          value={phone}
          label={{ name: 'Phone Number', key: 'phone' }}
          color="#20274D"
          type="tel"
          onChange={(e) => {
            setPhone(e.target.value);
          }}
        ></InputBar>
      </Col>
      <Col span={24}>
        <Row justify="center">
          <Col>
            {loading ? (
              <Loading />
            ) : data ? (
              <p>Thank You</p>
            ) : (
              <Button
                onClick={() => {
                  getLink(email, name, props.selectedProducts);
                }}
                {...((!emailRegex.test(email) ||
                  props.selectedProducts.length === 0) && { disabled: true })}
                style={{ fontWeight: 600 }}
                type="primary"
              >
                Confirm
              </Button>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
