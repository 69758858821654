import { LoadingOutlined } from '@ant-design/icons';
import React, { FC } from 'react';

export interface LoadingProps {}

export const Loading: FC<LoadingProps> = () => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 9999,
      }}
    >
      <LoadingOutlined
        style={{
          fontSize: '50px',
        }}
      />
    </div>
  );
};
