import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { backendUrl, DEFAULT_HEADERS } from '../../utils/constants';
import { Result, Button } from 'antd';
import { Loading } from '../Loading/Loading';

export const PaymentVerification: FC<{}> = (props) => {
  const location = useLocation();

  const query = new URLSearchParams(location.search);

  const reference = query.get('reference');

  const { push } = useHistory();

  const [status, setStatus] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);

  const verify = async (reference: string) => {
    setLoading(true);
    const response = await fetch(backendUrl + '/paystack-verify', {
      headers: DEFAULT_HEADERS,
      method: 'POST',
      body: JSON.stringify({
        reference,
      }),
    });

    const { data } = await response.json();

    if (data.success) {
      setStatus(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    reference && verify(reference);
  }, [reference]);

  const pageStatus = status ? 'success' : 'error';
  const title = status
    ? 'Successfully purchased'
    : 'Payment Verification Error';
  const subTitle = `Reference number: ${reference}. An email containing further details would be sent to you. Feel free to contact us`;

  return loading ? (
    <Loading />
  ) : (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Result
        status={pageStatus}
        title={title}
        subTitle={subTitle}
        extra={[
          <Button
            key="buy"
            onClick={() => {
              push('/store');
            }}
          >
            Back to Store
          </Button>,
        ]}
      />
    </div>
  );
};
