import { backendUrl, DEFAULT_HEADERS } from '../../utils/constants';

export const getStoreList = async () => {
  const response = await fetch(backendUrl + '/products', {
    headers: DEFAULT_HEADERS,
    method: 'GET',
  });

  return await response.json();
};
