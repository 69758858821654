import React, { FC, Dispatch, SetStateAction } from 'react';
import { Row, Col, Button } from 'antd';
import Text from 'antd/lib/typography/Text';
import { ShippingCard } from './ShippingCard';
import { useHistory } from 'react-router-dom';

export const Shipping: FC<{
  price: number;
  setPrice: Dispatch<SetStateAction<number>>;
}> = (props) => {
  const { push } = useHistory();

  const [shipping, setShipping] = React.useState<'Pickup' | 'Delivery'>(
    'Pickup'
  );

  return (
    <Row gutter={[{}, 24]}>
      <Col span={24}>
        <Text>Delivery Option</Text>
      </Col>
      <Col span={24}>
        <ShippingCard
          onClick={() => {
            setShipping('Pickup');
          }}
          checked={shipping === 'Pickup'}
          title="Collect at Store"
          description="Visit the salon to collect items"
          price={(0).toLocaleString('en-NG', {
            style: 'currency',
            currency: 'NGN',
          })}
        />
        <ShippingCard
          onClick={() => {
            setShipping('Delivery');
          }}
          checked={shipping === 'Delivery'}
          title="Delivery"
          description="You will receive a call from our delivery team to confirm your location. You will be charged for delivery separately"
          price=""
        />
      </Col>
      <Col span={24}>
        <Row justify="center">
          <Col>
            <Button
              type="primary"
              style={{
                backgroundColor: '#20274D',
                fontWeight: 600,
                minWidth: '120px',
              }}
              onClick={() => {
                push('/store/cart/checkout/payment?shipping=' + shipping);
              }}
            >
              Pay{' '}
              {props.price.toLocaleString('en-NG', {
                style: 'currency',
                currency: 'NGN',
              })}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
